import type Core from 'handsontable/core'
import {
  onAfterColumnResizeFunction,
  onAfterCreateColFunction,
  onAfterCreateRowFunction,
  onAfterRemoveColFunction,
  onAfterRemoveRowFunction,
  onAfterRowResizeFunction,
  repeatedNumber,
} from './utils'
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from '@/src/lib/handsontable'
import type { CustomPluginKey } from '@/src/lib/handsontable/plugins/CustomPluginBase'
import { CustomPluginBase } from '@/src/lib/handsontable/plugins/CustomPluginBase'

export type CustomCellSizePluginOptions = {
  enabled: boolean
  colWidths: number[]
  rowHeights: number[]
}

export class CustomCellSizePlugin extends CustomPluginBase {
  static get PLUGIN_KEY(): CustomPluginKey {
    return 'CUSTOM_CELL_SIZE_PLUGIN'
  }

  colWidths: number[]
  rowHeights: number[]

  constructor(hotInstance: Core) {
    super(hotInstance)
    this.colWidths = this.pluginSettings()?.colWidths ?? []
    this.rowHeights = this.pluginSettings()?.rowHeights ?? []
  }

  isEnabled = (): boolean => {
    return this.pluginSettings()?.enabled ?? false
  }

  pluginSettings = (): CustomCellSizePluginOptions | undefined => {
    return this.pluginSettingsByKey<CustomCellSizePluginOptions>(CustomCellSizePlugin.PLUGIN_KEY)
  }

  setup = () => {
    this.addHook('afterCreateCol', this.onAfterCreateCol)
    this.addHook('afterCreateRow', this.onAfterCreateRow)
    this.addHook('afterRemoveCol', this.onAfterRemoveCol)
    this.addHook('afterRemoveRow', this.onAfterRemoveRow)
    this.addHook('afterColumnResize', this.onAfterColumnResize)
    this.addHook('afterRowResize', this.onAfterRowResize)
    this.addHook('afterInit', this.onAfterInit)
  }

  onAfterInit = () => {
    const colSize = this.hotInstance.countCols()
    const rowSize = this.hotInstance.countRows()

    if (this.colWidths.length < colSize) {
      this.colWidths = [...this.colWidths, ...repeatedNumber(colSize - this.colWidths.length, DEFAULT_WIDTH)]
    } else if (this.colWidths.length > colSize) {
      this.colWidths = this.colWidths.slice(0, colSize)
    }

    if (this.rowHeights.length < rowSize) {
      this.rowHeights = [...this.rowHeights, ...repeatedNumber(rowSize - this.rowHeights.length, DEFAULT_HEIGHT)]
    } else if (this.rowHeights.length > rowSize) {
      this.rowHeights = this.rowHeights.slice(0, rowSize)
    }
  }

  onAfterCreateCol = (index: number, amount: number, _source?: string) => {
    this.colWidths = onAfterCreateColFunction(this.colWidths, index, amount)
    this.hotInstance.updateSettings({
      colWidths: this.colWidths,
    })
  }

  onAfterCreateRow = (index: number, amount: number, _source?: string) => {
    this.rowHeights = onAfterCreateRowFunction(this.rowHeights, index, amount)
    this.hotInstance.updateSettings({
      rowHeights: this.rowHeights,
    })
  }

  onAfterRemoveCol = (index: number, amount: number, _physicalColumns: number[], _source?: string) => {
    this.colWidths = onAfterRemoveColFunction(this.colWidths, index, amount)
    this.hotInstance.updateSettings({
      colWidths: this.colWidths,
    })
  }

  onAfterRemoveRow = (index: number, amount: number, _physicalRows: number[], _source?: string) => {
    this.rowHeights = onAfterRemoveRowFunction(this.rowHeights, index, amount)
    this.hotInstance.updateSettings({
      rowHeights: this.rowHeights,
    })
  }

  onAfterColumnResize = (newSize: number, column: number, _isDoubleClick: boolean) => {
    this.colWidths = onAfterColumnResizeFunction(this.colWidths, newSize, column)
  }

  onAfterRowResize = (newSize: number, row: number, _isDoubleClick: boolean) => {
    this.rowHeights = onAfterRowResizeFunction(this.rowHeights, newSize, row)
  }

  colWidthsForSave = (): number[] => {
    return [...this.colWidths]
  }

  rowHeightsForSave = (): number[] => {
    return [...this.rowHeights]
  }
}
