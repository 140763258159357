import { Icon } from '@iconify-icon/react'
import type { FC } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'

type StyledContentToolbarProps = {
  enableBgColor?: boolean
  onClick: (tag: string) => void
}

export const StyledContentToolbar: FC<StyledContentToolbarProps> = ({ enableBgColor = false, onClick }) => {
  return (
    <div className="d-flex gap-1 bg-white">
      <ButtonGroup>
        <Button variant="outline-secondary" size="sm" onClick={() => onClick('b')}>
          <Icon icon="bi:type-bold" className="tw-text-dark-500" inline />
        </Button>
        <Button variant="outline-secondary" size="sm" onClick={() => onClick('u')}>
          <Icon icon="bi:type-underline" className="tw-text-dark-500" inline />
        </Button>
      </ButtonGroup>
      <ButtonGroup>
        <Button variant="outline-secondary" size="sm" onClick={() => onClick('red')}>
          <Icon icon="material-symbols:format-color-text" className="tw-text-red-600" inline />
        </Button>
        <Button variant="outline-secondary" size="sm" onClick={() => onClick('blue')}>
          <Icon icon="material-symbols:format-color-text" className="tw-text-blue-600" inline />
        </Button>
        <Button variant="outline-secondary" size="sm" onClick={() => onClick('green')}>
          <Icon icon="material-symbols:format-color-text" className="tw-text-green-700" inline />
        </Button>
      </ButtonGroup>
      {enableBgColor && (
        <ButtonGroup>
          <Button variant="outline-secondary" size="sm" onClick={() => onClick('bg-yellow')}>
            <Icon icon="material-symbols:square-rounded" className="tw-text-yellow-200" inline />
          </Button>
        </ButtonGroup>
      )}
    </div>
  )
}
