import { debounce } from '@github/mini-throttle'
import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import Sortable from 'sortablejs'

// eslint-disable-next-line import/no-default-export
export default class extends Controller {
  static targets = [
    'form',
    'refreshSubmitter',
    'interviewItemGidContainer',
    'statement',
    'newStatementText',
    'statementList',
    'statementEditButton',
    'enableSortButton',
    'disableSortButton',
  ]

  declare readonly formTarget: HTMLFormElement
  declare readonly refreshSubmitterTarget: HTMLInputElement
  declare readonly interviewItemGidContainerTarget: HTMLDivElement
  declare readonly newStatementTextTarget: HTMLTextAreaElement
  declare readonly statementListTarget: HTMLDivElement
  declare readonly statementEditButtonTargets: readonly HTMLDivElement[]
  declare readonly enableSortButtonTarget: HTMLButtonElement
  declare readonly disableSortButtonTarget: HTMLButtonElement
  declare isAutoScrollEnabled: boolean
  declare sortable: Sortable

  connect = (): void => {
    // NOTE: 画面表示のタイミングでは書き起こしの要素への自動スクロールをしたくないので時間差で有効にしている
    this.setAutoScrollEnabled(false)
    setTimeout(() => {
      this.setAutoScrollEnabled(true)
    }, 1000)

    this.sortable = new Sortable(this.statementListTarget, {
      animation: 300,
      disabled: true,
      onStart: () => this.setAutoScrollEnabled(false),
      onEnd: () => this.setAutoScrollEnabled(true),
      onUpdate: this.updateStatementPosition,
    })

    this.enableSortButtonTarget.addEventListener('click', this.enableSort)
    this.disableSortButtonTarget.addEventListener('click', this.disableSort)
  }

  disconnect = (): void => {
    this.sortable.destroy()
  }

  statementTargetConnected = (element: HTMLElement): void => {
    if (!this.isAutoScrollEnabled) return

    this.debouncedScrollToElement(element)
  }

  enableSort = (): void => {
    this.sortable.option('disabled', false)
    this.disableSortButtonTarget.classList.remove('d-none')
    this.enableSortButtonTarget.classList.add('d-none')
    for (const button of this.statementEditButtonTargets) {
      button.classList.add('d-none')
    }
  }

  disableSort = (): void => {
    this.sortable.option('disabled', true)
    this.enableSortButtonTarget.classList.remove('d-none')
    this.disableSortButtonTarget.classList.add('d-none')
    for (const button of this.statementEditButtonTargets) {
      button.classList.remove('d-none')
    }
  }

  selectInterviewItem = (event: PointerEvent): void => {
    if (
      this.newStatementTextTarget.value.length > 0 &&
      !window.confirm('入力中の内容は上書きされます。よろしいですか？')
    ) {
      return
    }

    const { interviewItemGid } = (event.currentTarget as HTMLButtonElement).dataset
    if (!interviewItemGid) {
      console.debug('interviewItemGid is undefined.', event.currentTarget)
      return
    }

    while (this.interviewItemGidContainerTarget.firstChild) {
      this.interviewItemGidContainerTarget.firstChild.remove()
    }
    const hiddenTag = document.createElement('input')
    hiddenTag.type = 'hidden'
    hiddenTag.name = 'interview_transcription_statement[interview_item_gids][]'
    hiddenTag.value = interviewItemGid
    this.interviewItemGidContainerTarget.append(hiddenTag)

    this.formTarget.requestSubmit(this.refreshSubmitterTarget)
  }

  private setAutoScrollEnabled = (enabled: boolean): void => {
    console.debug('auto scroll enabled:', enabled)
    this.isAutoScrollEnabled = enabled
  }

  private scrollToElement = (element: HTMLElement): void => {
    console.debug('scroll to', element)
    element.scrollIntoView()
  }
  private debouncedScrollToElement = debounce(this.scrollToElement, 200)

  private updateStatementPosition({ item, newIndex }: { item: HTMLElement; newIndex?: number }): void {
    const url = item.dataset.moveUrl
    const token = Rails.csrfToken()
    if (!url || !token || newIndex === undefined) return

    console.debug('move', { target: item, position: newIndex + 1 })

    fetch(url, {
      method: 'PATCH',
      body: JSON.stringify({ position: newIndex + 1 }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
  }
}
