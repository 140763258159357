import type { MenuItemConfig } from 'handsontable/plugins/contextMenu'
import { CustomPropertyPlugin } from './core'

const toggleAttributeCallback = (core: CustomPropertyPlugin, attribute: 'disabled' | 'exclusive') => {
  return () => {
    const customProperties = core.findOrCreateCustomProperties(core.selectedCells())
    if (customProperties.length === 0) return

    const isAllCellsApplied = customProperties.every((customProperty) => customProperty[attribute])
    if (isAllCellsApplied) {
      for (const customProperty of customProperties) {
        customProperty[attribute] = undefined
      }
      core.runHooksAndRender(CustomPropertyPlugin.REMOVE_CUSTOM_PROPERTY_EVENT)
    } else {
      for (const customProperty of customProperties) {
        customProperty[attribute] = true
      }
      core.runHooksAndRender(CustomPropertyPlugin.SET_CUSTOM_PROPERTY_EVENT)
    }
  }
}

export const toggleAttributeDisabledCommand = (core: CustomPropertyPlugin): MenuItemConfig => {
  return {
    key: 'toggleAttributeDisabled',
    name: '無効',
    callback: toggleAttributeCallback(core, 'disabled'),
  }
}

export const toggleAttributeExclusiveCommand = (core: CustomPropertyPlugin): MenuItemConfig => {
  return {
    key: 'toggleAttributeExclusive',
    name: '排他',
    callback: toggleAttributeCallback(core, 'exclusive'),
  }
}
