import type { MenuItemConfig } from 'handsontable/plugins/contextMenu'
import { CustomPropertyPlugin } from './core'
import { CustomMergeCellsPlugin } from '@/src/lib/handsontable/plugins/CustomMergeCellsPlugin'
import { buildReferenceId, generateCellReferenceId } from '@/src/lib/referenceUtils'

const generateReferenceId = (core: CustomPropertyPlugin): string => {
  const usedIds = new Set(core.customProperties.map((property) => property.referenceId).filter(Boolean))

  // セルの参照IDを生成, 一つの質問内で重複しないよう3回までリトライする
  for (let i = 0; i < 3; i++) {
    const referenceId = generateCellReferenceId()
    if (!usedIds.has(referenceId)) {
      return referenceId
    }
  }

  throw 'Failed to generate reference ID.'
}

export const setReferenceIdCommand = (core: CustomPropertyPlugin): MenuItemConfig => {
  return {
    key: 'setCustomReferenceId',
    name: '参照を取得(アドレス表示)',
    callback: () => {
      const questionPosition = core.questionPosition()
      if (!questionPosition) return

      const target = core.hot.getSelectedRangeLast()?.to
      if (!target) return

      const customProperty = core.findOrCreateCustomProperty(target)
      if (!customProperty.referenceId) {
        customProperty.referenceId = generateReferenceId(core)
        core.runHooksAndRender(CustomPropertyPlugin.SET_CUSTOM_PROPERTY_EVENT)
      }
      navigator.clipboard?.writeText(buildReferenceId(`Q${questionPosition}`, customProperty.referenceId))
    },
    disabled: () => core.selectedCells().length !== 1,
  }
}

export const setReferenceIdForValueCommand = (core: CustomPropertyPlugin): MenuItemConfig => {
  return {
    key: 'setCustomReferenceIdForValue',
    name: '参照を取得(値表示)',
    callback: () => {
      const questionPosition = core.questionPosition()
      if (!questionPosition) return

      const targets = core.selectedCells()
      if (!targets) return

      // NOTE: handsontable の表に直接貼り付けられるように2次元配列を用意している
      const minRow = Math.min(...targets.map((target) => target.row))
      const maxRow = Math.max(...targets.map((target) => target.row))
      const minCol = Math.min(...targets.map((target) => target.col))
      const maxCol = Math.max(...targets.map((target) => target.col))
      const rowCount = maxRow - minRow + 1
      const colCount = maxCol - minCol + 1
      // eslint-disable-next-line unicorn/no-new-array
      const result: string[][] = new Array(rowCount)
      for (let i = 0; i < rowCount; i++) {
        // eslint-disable-next-line unicorn/no-new-array
        result[i] = new Array(colCount)
      }

      // NOTE: 配列にデータを詰めた後に、改行とタブで連結した文字列をクリップボードに用意する
      const customMergeCellsPlugin: CustomMergeCellsPlugin = core.hot.getPlugin(CustomMergeCellsPlugin.PLUGIN_KEY)
      for (const target of targets) {
        const y = target.row - minRow
        const x = target.col - minCol

        // 結合されたセルの最初のセル以外はコピー不要
        if (customMergeCellsPlugin.isRestOfMergedCell({ row: target.row, col: target.col })) {
          result[y][x] = ''
          continue
        }

        const customProperty = core.findOrCreateCustomProperty(target)
        if (!customProperty.referenceId) {
          customProperty.referenceId = generateReferenceId(core)
        }

        result[y][x] = buildReferenceId(`Q${questionPosition}`, customProperty.referenceId, true)
      }
      core.hot.runHooks(CustomPropertyPlugin.SET_CUSTOM_PROPERTY_EVENT)
      navigator.clipboard?.writeText(result.map((row) => row.join('\t')).join('\n'))
      core.hot.runHooks('afterCopy', result, [{ startRow: minRow, startCol: minCol, endRow: maxRow, endCol: maxCol }])
      core.hot.render()
    },
    disabled: () => core.selectedCells().length === 0,
  }
}

export const removeReferenceIdCommand = (core: CustomPropertyPlugin): MenuItemConfig => {
  return {
    key: 'removeCustomReferenceId',
    name: '参照を削除',
    callback: () => {
      const customProperties = core
        .findCustomProperties(core.selectedCells())
        .filter((customProperty) => customProperty.referenceId)
      if (customProperties.length === 0) return

      for (const customProperty of customProperties) {
        customProperty.referenceId = undefined
      }
      core.runHooksAndRender(CustomPropertyPlugin.REMOVE_CUSTOM_PROPERTY_EVENT)
    },
    disabled: () => !core.selectedCells().some((cell) => core.findCustomProperty(cell)?.referenceId),
  }
}
