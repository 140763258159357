import type { MenuItemConfig } from 'handsontable/plugins/contextMenu'
import type { CustomPropertyPlugin } from './core'

const findLastExclusiveCellRow = (plugin: CustomPropertyPlugin): number => {
  const exclusiveCellRows = plugin.customProperties
    .filter((property) => property.exclusive)
    .map((property) => property.row)
  return Math.max(...exclusiveCellRows, 0)
}

const findLastExclusiveCellCol = (plugin: CustomPropertyPlugin): number => {
  const exclusiveCellCols = plugin.customProperties
    .filter((property) => property.exclusive)
    .map((property) => property.col)
  return Math.max(...exclusiveCellCols, 0)
}

const countDeletableRows = (plugin: CustomPropertyPlugin): number => {
  const rowCount = plugin.hotInstance.countRows()
  const emptyRowCount = plugin.hotInstance.countEmptyRows(true)
  const lastExclusiveCellRow = findLastExclusiveCellRow(plugin)

  return Math.min(rowCount - (lastExclusiveCellRow + 1), emptyRowCount)
}

const countDeletableCols = (plugin: CustomPropertyPlugin): number => {
  const colCount = plugin.hotInstance.countCols()
  const emptyColCount = plugin.hotInstance.countEmptyCols(true)
  const lastExclusiveCellCol = findLastExclusiveCellCol(plugin)

  return Math.min(colCount - (lastExclusiveCellCol + 1), emptyColCount)
}

export const removeUnusedCellsCommand = (plugin: CustomPropertyPlugin): MenuItemConfig => {
  return {
    key: 'removeUnusedCells',
    name: '不要な行・列を一括削除',
    callback: () => {
      const deletableRowCount = countDeletableRows(plugin)
      if (deletableRowCount > 0) {
        plugin.hotInstance.alter('remove_row', plugin.hotInstance.countRows() - deletableRowCount, deletableRowCount)
      }

      const deletableColCount = countDeletableCols(plugin)
      if (deletableColCount > 0) {
        plugin.hotInstance.alter('remove_col', plugin.hotInstance.countCols() - deletableColCount, deletableColCount)
      }
    },
    disabled: () => countDeletableRows(plugin) === 0 && countDeletableCols(plugin) === 0,
  }
}
