/* eslint-disable github/unescaped-html-literal */

// NOTE: handsontable パフォーマンス改善の影響もあり、排他セルの表示は装飾用のタグ機能で実装している
type CustomTagName = 'red' | 'blue' | 'green' | 'b' | 'u' | 'exclusive'

type ParseCustomTagReturn = {
  tagName: CustomTagName | undefined
  tagType: 'open' | 'close' | undefined
}

const EXCLUSIVE_MARK = '<exclusive>排他</exclusive>'

const SPLIT_BY_CUSTOM_TAG_PATTERN = /(<\/?(?:red|blue|green|bg-yellow|b|u|exclusive)>)/
const CUSTOM_TAG_PATTERN = /<(\/)?(red|blue|green|bg-yellow|b|u|exclusive)>/
export const CUSTOM_TAG_OPEN_PATTERN = /<(red|blue|green|bg-yellow|b|u|exclusive)>/g
export const CUSTOM_TAG_CLOSE_PATTERN = /<\/(red|blue|green|bg-yellow|b|u|exclusive)>/g

export const isEmpty = (value: string | undefined | null): boolean => {
  return value === undefined || value === null || value.trim() === ''
}

export const emptyToUndefined = (value: string | undefined): string | undefined => {
  return isEmpty(value) ? undefined : value
}

export const applyExclusiveMark = (value: string | undefined, isExclusive: boolean | undefined) => {
  const parts = []
  if (value) parts.push(value)
  if (isExclusive) parts.push(EXCLUSIVE_MARK)

  return parts.join(' ')
}

export const applyCustomTextStyle = (value?: string): string => {
  const tagNameStack: CustomTagName[] = []
  const results: string[] = []

  for (const part of splitByCustomTag(value)) {
    const { tagName, tagType } = parseCustomTag(part)
    if (!tagName) {
      results.push(escapeHtml(part))
      continue
    }

    // 開始タグは内容に応じて <span> で出力
    if (tagType === 'open') {
      tagNameStack.push(tagName)
      results.push(
        {
          red: '<span class="tw-text-red-600">',
          blue: '<span class="tw-text-blue-600">',
          green: '<span class="tw-text-green-700">',
          'bg-yellow': '<span class="tw-bg-yellow-200">',
          b: '<span class="fw-bold">',
          u: '<span class="text-decoration-underline">',
          exclusive: '<span class="tw-text-white tw-rounded tw-bg-blue-500 px-1">',
        }[tagName],
      )
      continue
    }

    // 閉じタグは開始タグと対応が合っていれば </span> で出力
    if (tagNameStack.at(-1) === tagName) {
      tagNameStack.pop()
      results.push('</span>')
      continue
    }

    // 余計な閉じタグがある場合はそのまま出力
    results.push(escapeHtml(part))
  }

  // 不足している分の閉じタグを追加
  while (tagNameStack.pop()) {
    results.push('</span>')
  }

  return results.join('')
}

const escapeHtml = (value: string) => {
  // eslint-disable-next-line unicorn/prefer-string-replace-all
  return value.replace(/["&'<>`]/g, (match) => {
    return (
      {
        "'": '&#x27;',
        '"': '&quot;',
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '`': '&#x60;',
      }[match] ?? ''
    )
  })
}

const splitByCustomTag = (value?: string) => {
  return value?.split(SPLIT_BY_CUSTOM_TAG_PATTERN).filter(Boolean) ?? []
}

const parseCustomTag = (value: string): ParseCustomTagReturn => {
  const match = value.match(CUSTOM_TAG_PATTERN)
  if (!match) {
    return {
      tagName: undefined,
      tagType: undefined,
    }
  }

  return {
    tagName: match[2] as CustomTagName,
    tagType: match[1] === '/' ? 'close' : 'open',
  }
}
