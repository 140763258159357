import { toast } from 'react-toastify'

export const showSuccess = (message: string): void => {
  toast.success(message, {
    autoClose: 1000,
  })
}

export const showError = (message: string): void => {
  toast.error(message, {
    autoClose: 10_000,
  })
}

export const showProgress = <T>(promise: Promise<T>, message = '更新中...') => {
  return toast.promise(promise, { pending: message })
}

export const showErrors = (errors: string[]): void => {
  // TODO: まとめて表示するようにする
  for (const error of errors) {
    showError(error)
  }
}
