import { Controller } from '@hotwired/stimulus'

// eslint-disable-next-line import/no-default-export
export default class extends Controller {
  static targets = ['form', 'speaker', 'textarea']

  declare readonly formTarget: HTMLFormElement
  declare readonly speakerTargets: readonly HTMLInputElement[]
  declare readonly textareaTarget: HTMLTextAreaElement

  textareaTargetConnected = (element: HTMLTextAreaElement) => {
    element.addEventListener('keydown', this.onKeydown)
    element.focus()
  }

  focus = (): void => {
    console.debug('focus', this.textareaTarget)
    this.textareaTarget.focus()
  }

  onKeydown = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === 'Enter') {
      event.preventDefault()
      this.formTarget.requestSubmit()
    }

    if ((event.ctrlKey || event.altKey) && event.key === '1') {
      event.preventDefault()
      this.speakerTargets[0].checked = true
    }
    if ((event.ctrlKey || event.altKey) && event.key === '2') {
      event.preventDefault()
      this.speakerTargets[1].checked = true
    }
  }
}
