import Handsontable from 'handsontable'
import type Core from 'handsontable/core'
import { TextEditor } from 'handsontable/editors'
import type { Root } from 'react-dom/client'
import { createRoot } from 'react-dom/client'
import { StyledContentToolbar } from '@/src/components/Content/EditableStyledContent'
import type { QuestionReferenceMap } from '@/src/lib/referenceUtils'
import { convertReferenceIdForEdit, convertReferenceIdForSave } from '@/src/lib/referenceUtils'
import { wrapSelectionWithTag } from '@/src/lib/textareaUtils'

// NOTE: handsontable 経由で値を受け渡す場合は型が使用できないので any を利用する
const questionReferenceMap = (instance: Core): QuestionReferenceMap => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (instance.getSettings() as any).questionReferenceMap
}

// TODO: セル編集の暫定実装、今後見直していく予定
export class StyledContentEditor extends TextEditor {
  declare reactRoot: Root | undefined
  declare selectionStart: number | undefined
  declare selectionEnd: number | undefined

  prepare(...props: Parameters<TextEditor['prepare']>): void {
    super.prepare(...props)

    // TODO: 1行目の場合に上がちょっと切れている & スクロール位置によってまだ少し見切れるパターンあり
    const toolbar = this.getToolbar()
    if (toolbar) {
      const [row, col] = props
      toolbar.classList.remove('top-100', 'bottom-100', 'start-0', 'end-0')
      toolbar.classList.add(row > 1 ? 'bottom-100' : 'top-100')
      toolbar.classList.add(col > 1 ? 'end-0' : 'start-0')
    }
  }

  createElements(): void {
    super.createElements()

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const handleClick = (tag: string) => {
      const target = this.TEXTAREA as HTMLTextAreaElement

      // FIXME: セル結合している場合にテキストエリアの選択範囲が取得できなくなるため別途記録した値で復元している
      if (this.selectionStart !== undefined && this.selectionEnd !== undefined) {
        target.setSelectionRange(this.selectionStart, this.selectionEnd)
        this.selectionStart = undefined
        this.selectionEnd = undefined
      }

      wrapSelectionWithTag(target, tag)
      this.refreshDimensions()
    }

    const toolbar = document.createElement('div')
    toolbar.classList.add('styled-content-editor-toolbar', 'position-absolute')
    // TODO: React@18 の暫定対応, handsontable 側の機能で実装を見直しする
    if (!this.reactRoot) {
      this.reactRoot = createRoot(toolbar)
    }
    this.reactRoot.render(<StyledContentToolbar onClick={handleClick} />)

    Handsontable.dom.empty(this.TEXTAREA_PARENT)
    this.TEXTAREA_PARENT.append(this.TEXTAREA)
    this.TEXTAREA_PARENT.append(toolbar)

    // FIXME: セル結合している場合にテキストエリアの選択範囲が取得できなくなるため別途記録している
    toolbar.addEventListener('mouseenter', () => {
      const target = this.TEXTAREA as HTMLTextAreaElement
      this.selectionStart = target.selectionStart
      this.selectionEnd = target.selectionEnd
    })
  }

  getToolbar = () => {
    return this.TEXTAREA_PARENT.querySelector<HTMLDivElement>('.styled-content-editor-toolbar')
  }

  setValue(value: string): void {
    super.setValue(convertReferenceIdForEdit(value, questionReferenceMap(this.hot)))
  }

  getValue(): string {
    return convertReferenceIdForSave(super.getValue(), questionReferenceMap(this.hot))
  }

  beginEditing(...props: Parameters<TextEditor['beginEditing']>): void {
    super.beginEditing(...props)
    // NOTE: handsontable の他のパーツより前面に表示するため
    this.TEXTAREA_PARENT.style.zIndex = '1000'
  }

  finishEditing(...props: Parameters<TextEditor['finishEditing']>): void {
    super.finishEditing(...props)
    // NOTE: 非表示で要素は残るので他の要素の邪魔にならないように
    this.TEXTAREA_PARENT.style.zIndex = '-1'
  }

  destroy() {
    // TODO: よりよいやり方が分かったら見直す
    // NOTE: そのまま unmount すると他コンポーネントのレンダリングと同時に実行されて警告が表示される。
    //       そのため、setTimeout 経由で他コンポーネントのレンダリング後に実行するようにしている。
    setTimeout(() => {
      this.reactRoot?.unmount()
    }, 0)
  }
}
