import { onConnect } from '@sonicgarden/onconnect'

export const initDisableWheelInNumberField = () => {
  onConnect('input[type="number"]', (el) => {
    // 数値入力フィールドでマウスホイールで値が変わるのは事故のもとなので無効化している
    // eslint-disable-next-line github/require-passive-events
    el.addEventListener('wheel', (e) => {
      e.preventDefault()
    })
  })
}
