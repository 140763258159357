import type { MenuItemConfig, SubmenuItemConfig } from 'handsontable/plugins/contextMenu'
import { CustomPropertyPlugin } from './core'

const setTextAlignmentLeftCommand = (core: CustomPropertyPlugin): SubmenuItemConfig => {
  return {
    key: 'textAlignment:left',
    name: '左揃え',
    callback: () => {
      const customProperties = core
        .findCustomProperties(core.selectedCells())
        .filter((customProperty) => customProperty.textAlignment)
      if (customProperties.length === 0) return

      for (const customProperty of customProperties) {
        customProperty.textAlignment = undefined
      }
      core.runHooksAndRender(CustomPropertyPlugin.REMOVE_CUSTOM_PROPERTY_EVENT)
    },
  }
}

const setTextAlignmentRightCommand = (core: CustomPropertyPlugin): SubmenuItemConfig => {
  return {
    key: 'textAlignment:right',
    name: '右揃え',
    callback: () => {
      const customProperties = core
        .findOrCreateCustomProperties(core.selectedCells())
        .filter((customProperty) => customProperty.textAlignment !== 'right')
      if (customProperties.length === 0) return

      for (const customProperty of customProperties) {
        customProperty.textAlignment = 'right'
      }
      core.runHooksAndRender(CustomPropertyPlugin.SET_CUSTOM_PROPERTY_EVENT)
    },
  }
}

const setTextAlignmentCenterCommand = (core: CustomPropertyPlugin): SubmenuItemConfig => {
  return {
    key: 'textAlignment:center',
    name: '中央揃え',
    callback: () => {
      const customProperties = core
        .findOrCreateCustomProperties(core.selectedCells())
        .filter((customProperty) => customProperty.textAlignment !== 'center')
      if (customProperties.length === 0) return

      for (const customProperty of customProperties) {
        customProperty.textAlignment = 'center'
      }
      core.runHooksAndRender(CustomPropertyPlugin.SET_CUSTOM_PROPERTY_EVENT)
    },
  }
}

export const textAlignmentCommands = (core: CustomPropertyPlugin): MenuItemConfig => {
  return {
    key: 'textAlignment',
    name: '配置',
    submenu: {
      items: [
        setTextAlignmentLeftCommand(core),
        setTextAlignmentRightCommand(core),
        setTextAlignmentCenterCommand(core),
      ],
    },
  }
}
