import type CellRange from 'handsontable/3rdparty/walkontable/src/cell/range'
import type { CustomMergeCell } from './core'

export const cellRangeToCustomMergeCell = (cellRange: CellRange): CustomMergeCell => {
  const rowStart = Math.max(cellRange.from.row, 0)
  const colStart = Math.max(cellRange.from.col, 0)

  return {
    row: rowStart,
    col: colStart,
    rowspan: cellRange.to.row - rowStart + 1,
    colspan: cellRange.to.col - colStart + 1,
  }
}

export const isOverlap = (a: CustomMergeCell, b: CustomMergeCell): boolean => {
  const isOverlapX = a.col < b.col + b.colspan && b.col < a.col + a.colspan
  const isOverlapY = a.row < b.row + b.rowspan && b.row < a.row + a.rowspan
  return isOverlapX && isOverlapY
}

export const onAfterCreateColFunction = (
  targets: readonly CustomMergeCell[],
  start: number,
  amount: number,
): CustomMergeCell[] => {
  return targets.map((target) => {
    if (start <= target.col) {
      return { ...target, col: target.col + amount }
    } else if (start <= target.col + target.colspan - 1) {
      return { ...target, colspan: target.colspan + amount }
    } else {
      return { ...target }
    }
  })
}

export const onAfterCreateRowFunction = (
  targets: readonly CustomMergeCell[],
  start: number,
  amount: number,
): CustomMergeCell[] => {
  return targets.map((target) => {
    if (start <= target.row) {
      return { ...target, row: target.row + amount }
    } else if (start <= target.row + target.rowspan - 1) {
      return { ...target, rowspan: target.rowspan + amount }
    } else {
      return { ...target }
    }
  })
}

export const onAfterRemoveColFunction = (
  targets: readonly CustomMergeCell[],
  start: number,
  amount: number,
): CustomMergeCell[] => {
  const results: CustomMergeCell[] = []
  const end = start + amount - 1

  for (const target of targets) {
    const targetStart = target.col
    const targetEnd = target.col + target.colspan - 1
    const targetSize = target.colspan

    if (end < targetStart) {
      results.push({ ...target, col: targetStart - amount })
    } else if (targetEnd < start) {
      results.push({ ...target })
    } else if (start <= targetStart && targetEnd <= end) {
      continue
    } else if (start < targetStart) {
      const removed = amount - (targetStart - start)
      results.push({ ...target, col: start, colspan: targetSize - removed })
    } else if (targetEnd < end) {
      const removed = amount - (end - targetEnd)
      results.push({ ...target, colspan: targetSize - removed })
    } else {
      results.push({ ...target, colspan: targetSize - amount })
    }
  }

  return results.filter((result) => result.rowspan > 1 || result.colspan > 1)
}

export const onAfterRemoveRowFunction = (
  targets: readonly CustomMergeCell[],
  start: number,
  amount: number,
): CustomMergeCell[] => {
  const results: CustomMergeCell[] = []
  const end = start + amount - 1

  for (const target of targets) {
    const targetStart = target.row
    const targetEnd = target.row + target.rowspan - 1
    const targetSize = target.rowspan

    if (end < targetStart) {
      results.push({ ...target, row: targetStart - amount })
    } else if (targetEnd < start) {
      results.push({ ...target })
    } else if (start <= targetStart && targetEnd <= end) {
      continue
    } else if (start < targetStart) {
      const removed = amount - (targetStart - start)
      results.push({ ...target, row: start, rowspan: targetSize - removed })
    } else if (targetEnd < end) {
      const removed = amount - (end - targetEnd)
      results.push({ ...target, rowspan: targetSize - removed })
    } else {
      results.push({ ...target, rowspan: targetSize - amount })
    }
  }

  return results.filter((result) => result.rowspan > 1 || result.colspan > 1)
}
