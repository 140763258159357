import type { CustomProperty } from './core'
import { range } from '@/src/lib/arrayUtils'

export const onAfterCreateColFunction = (
  targets: readonly CustomProperty[],
  index: number,
  amount: number,
): CustomProperty[] => {
  const results: CustomProperty[] = []

  for (const target of targets) {
    if (index <= target.col) {
      if (index === target.col) {
        for (const col of range(index, index + amount - 1)) {
          results.push({ ...target, col, exclusive: undefined })
        }
      }
      results.push({ ...target, col: target.col + amount })
    } else {
      results.push({ ...target })
    }
  }

  return results
}

export const onAfterCreateRowFunction = (
  targets: readonly CustomProperty[],
  index: number,
  amount: number,
): CustomProperty[] => {
  const results: CustomProperty[] = []

  for (const target of targets) {
    if (index <= target.row) {
      if (index === target.row) {
        for (const row of range(index, index + amount - 1)) {
          results.push({ ...target, row, exclusive: undefined })
        }
      }
      results.push({ ...target, row: target.row + amount })
    } else {
      results.push({ ...target })
    }
  }

  return results
}

export const onAfterRemoveColFunction = (
  targets: readonly CustomProperty[],
  index: number,
  amount: number,
): CustomProperty[] => {
  const results: CustomProperty[] = []
  const removeStart = index
  const removeEnd = index + amount - 1

  for (const target of targets) {
    if (removeStart <= target.col && target.col <= removeEnd) {
      continue
    } else if (removeEnd < target.col) {
      results.push({ ...target, col: target.col - amount })
    } else {
      results.push({ ...target })
    }
  }

  return results
}

export const onAfterRemoveRowFunction = (
  targets: readonly CustomProperty[],
  index: number,
  amount: number,
): CustomProperty[] => {
  const results: CustomProperty[] = []
  const removeStart = index
  const removeEnd = index + amount - 1

  for (const target of targets) {
    if (removeStart <= target.row && target.row <= removeEnd) {
      continue
    } else if (removeEnd < target.row) {
      results.push({ ...target, row: target.row - amount })
    } else {
      results.push({ ...target })
    }
  }

  return results
}
