/* eslint-disable unicorn/no-await-expression-member */

import { asyncRewrap, rewrap } from '@sonicgarden/rewrap'
import { ToastContainerWrapper } from '@/src/components/ToastContainerWrapper'

export const initReact = (): void => {
  rewrap('x-toast-container', ToastContainerWrapper)
  asyncRewrap('x-copy-button', async () => (await import('@/src/components/CopyButton')).CopyButton)
  asyncRewrap('x-select', async () => (await import('react-select')).default)
  asyncRewrap('x-creatable-select', async () => (await import('react-select/creatable')).default)
  asyncRewrap('x-survey', async () => (await import('@/src/pages/Survey')).ApolloSurvey)
  asyncRewrap(
    'x-searchable-select',
    async () => (await import('@/src/components/Basic/SearchableSelect')).SearchableSelect,
  )
  asyncRewrap(
    'x-styled-content-editor',
    async () => (await import('@/src/components/Content/EditableStyledContent')).StyledContentEditor,
  )
  asyncRewrap(
    'x-styled-content-preview',
    async () => (await import('@/src/components/Content/EditableStyledContent')).StyledContentPreview,
  )
}
