import { Application } from '@hotwired/stimulus'
import Sortable from '@stimulus-components/sortable'
import Modal from '@/src/stimulus/controllers/modal_controller'
import ScheduleMenuTrigger from '@/src/stimulus/controllers/schedule_menu_trigger_controller'
import TranscriptionAssistant from '@/src/stimulus/controllers/transcription_assistant_controller'
import TranscriptionForm from '@/src/stimulus/controllers/transcription_form_controller'

const application = Application.start()

application.register('sortable', Sortable)
application.register('modal', Modal)
application.register('schedule-menu-trigger', ScheduleMenuTrigger)
application.register('transcription-assistant', TranscriptionAssistant)
application.register('transcription-form', TranscriptionForm)

// application.debug = true
