import type { MenuItemConfig } from 'handsontable/plugins/contextMenu'
import { alterColumnsCommands } from './alterColumnsCommands'
import { alterRowsCommands } from './alterRowsCommands'
import type { CustomPluginKey } from '@/src/lib/handsontable/plugins/CustomPluginBase'
import { CustomPluginBase } from '@/src/lib/handsontable/plugins/CustomPluginBase'

export type CustomCommandsPluginOptions = {
  enabled: boolean
}

export class CustomCommandsPlugin extends CustomPluginBase {
  static get PLUGIN_KEY(): CustomPluginKey {
    return 'CUSTOM_COMMANDS_PLUGIN'
  }

  setup = () => {
    // NOTE: addHook に本来の引数のメソッドを渡すとエラーになるため `as any` している
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    this.addHook('afterContextMenuDefaultOptions', this.onAfterContextMenuDefaultOptions as any)
  }

  isEnabled = (): boolean => {
    return this.pluginSettings()?.enabled ?? false
  }

  pluginSettings = (): CustomCommandsPluginOptions | undefined => {
    return this.pluginSettingsByKey<CustomCommandsPluginOptions>(CustomCommandsPlugin.PLUGIN_KEY)
  }

  onAfterContextMenuDefaultOptions = (predefinedItems: { items: MenuItemConfig[] }) => {
    predefinedItems.items.push(alterRowsCommands(this), alterColumnsCommands(this))
  }
}
