import { flashMessages } from '@/src/lib/railsUtils'
import { showSuccess, showError } from '@/src/lib/toast'

const FLASH_KEYS = ['notice', 'alert'] as const

export const displayFlashMessage = (): void => {
  const flash = flashMessages()
  if (!flash) return

  for (const name of FLASH_KEYS) {
    const message = flash[name]
    if (typeof message !== 'string') continue

    if (name === 'notice') {
      showSuccess(message)
    } else {
      showError(message)
    }
  }
}
