// NOTE: turboのshadow domサポートが改善されるまでの暫定対応用ラッパー要素
// SEE: https://github.com/hotwired/turbo/pull/758
export class WorkaroundClickable extends HTMLElement {
  connectedCallback(): void {
    this.addEventListener('click', this.onClick)
  }

  disconnectedCallback(): void {
    this.removeEventListener('click', this.onClick)
  }

  onClick = (event: Event): void => {
    if (event.target === this) return

    event.preventDefault()
    event.stopPropagation()
    this.click()
  }
}

declare global {
  interface Window {
    WorkaroundClickable: typeof WorkaroundClickable
  }
}

if (!window.customElements.get('workaround-clickable')) {
  window.WorkaroundClickable = WorkaroundClickable
  window.customElements.define('workaround-clickable', WorkaroundClickable)
}
