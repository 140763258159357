import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from '@/src/lib/handsontable/constants'

export const onAfterCreateColFunction = (colWidths: readonly number[], index: number, amount: number): number[] => {
  const results = [...colWidths]
  results.splice(index, 0, ...repeatedNumber(amount, DEFAULT_WIDTH))
  return results
}

export const onAfterRemoveColFunction = (colWidths: readonly number[], index: number, amount: number): number[] => {
  const results = [...colWidths]
  results.splice(index, amount)
  return results
}

export const onAfterCreateRowFunction = (rowHeights: readonly number[], index: number, amount: number): number[] => {
  const results = [...rowHeights]
  results.splice(index, 0, ...repeatedNumber(amount, DEFAULT_HEIGHT))
  return results
}

export const onAfterRemoveRowFunction = (rowHeights: readonly number[], index: number, amount: number): number[] => {
  const results = [...rowHeights]
  results.splice(index, amount)
  return results
}

export const onAfterColumnResizeFunction = (
  colWidths: readonly number[],
  newSize: number,
  column: number,
): number[] => {
  const results = [...colWidths]
  results.splice(column, 1, newSize)
  return results
}

export const onAfterRowResizeFunction = (rowHeights: readonly number[], newSize: number, row: number) => {
  const results = [...rowHeights]
  results.splice(row, 1, newSize)
  return results
}

export const repeatedNumber = (amount: number, value: number): readonly number[] => {
  return Array.from({ length: amount }).fill(value) as number[]
}
