// NOTE: handsontableの中でReactDOM.renderを使用しているため、ReactのContextで状態を渡すことができない。
//       代わりにapolloの機能を使用して状態をグローバルに管理している。
import { makeVar, useReactiveVar } from '@apollo/client'
import type { CellReferenceMap, QuestionReferenceMap } from '@/src/lib/referenceUtils'

export const questionReferenceMapVar = makeVar<QuestionReferenceMap>({})
export const cellReferenceMapVar = makeVar<CellReferenceMap>({})

export const useQuestionReferenceMap = () => {
  return useReactiveVar(questionReferenceMapVar)
}

export const useCellReferenceMap = () => {
  return useReactiveVar(cellReferenceMapVar)
}
