import classNames from 'classnames'
import type { ForwardRefRenderFunction } from 'react'
import React from 'react'
import type { TextareaAutosizeProps } from 'react-textarea-autosize'
import TextareaAutosize from 'react-textarea-autosize'

export type BsAutosizeTextareaProps = TextareaAutosizeProps

const BsAutosizeTextareaFn: ForwardRefRenderFunction<HTMLTextAreaElement, BsAutosizeTextareaProps> = (
  { className, ...props },
  ref,
) => {
  return <TextareaAutosize ref={ref} {...props} className={classNames('form-control', className)} />
}

export const BsAutosizeTextarea = React.forwardRef(BsAutosizeTextareaFn)
