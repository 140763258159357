import type CellCoords from 'handsontable/3rdparty/walkontable/src/cell/coords'
import type { MenuItemConfig, SubmenuItemConfig } from 'handsontable/plugins/contextMenu'
import type { CustomCommandsPlugin } from './core'

type SelectedRowsReturn = {
  index: number
  amount: number
}

const getSelectedRows = (plugin: CustomCommandsPlugin): SelectedRowsReturn | false => {
  const selectedRange = plugin.selectedRange()
  if (selectedRange.length !== 1) {
    return false
  }

  const cells: CellCoords[] = selectedRange[0].getAll()
  if (cells.some((cell) => cell.row < 0)) {
    return false
  }

  const rows = cells.map((cell) => cell.row)
  const min = Math.min(...rows)
  const max = Math.max(...rows)

  return {
    index: min,
    amount: max - min + 1,
  }
}

const insertRowsAboveCommand = (plugin: CustomCommandsPlugin): SubmenuItemConfig => {
  return {
    key: 'alterRows:above',
    name: '上に追加',
    callback: () => {
      const selectedRows = getSelectedRows(plugin)
      if (!selectedRows) {
        return
      }

      plugin.hot.alter('insert_row_above', selectedRows.index, selectedRows.amount)
    },
  }
}

const insertRowsBelowCommand = (plugin: CustomCommandsPlugin): SubmenuItemConfig => {
  return {
    key: 'alterRows:below',
    name: '下に追加',
    callback: () => {
      const selectedRows = getSelectedRows(plugin)
      if (!selectedRows) {
        return
      }

      plugin.hot.alter('insert_row_below', selectedRows.index + selectedRows.amount - 1, selectedRows.amount)
    },
  }
}

const removeRowsCommand = (plugin: CustomCommandsPlugin): SubmenuItemConfig => {
  return {
    key: 'alterRows:remove',
    name: '削除',
    callback: () => {
      const selectedRows = getSelectedRows(plugin)
      if (!selectedRows) {
        return
      }
      if (selectedRows.index === 0 && selectedRows.amount === plugin.hotInstance.countRows()) {
        selectedRows.index = 1
        selectedRows.amount -= 1
      }

      plugin.hot.alter('remove_row', selectedRows.index, selectedRows.amount, 'alterRows:remove')
    },
  }
}

export const alterRowsCommands = (plugin: CustomCommandsPlugin): MenuItemConfig => {
  return {
    key: 'alterRows',
    name: '行',
    submenu: {
      items: [insertRowsAboveCommand(plugin), insertRowsBelowCommand(plugin), removeRowsCommand(plugin)],
    },
    disabled: () => !getSelectedRows(plugin),
  }
}
