import classNames from 'classnames'
import type CellCoords from 'handsontable/3rdparty/walkontable/src/cell/coords'
import type { RangeType } from 'handsontable/common'
import type Core from 'handsontable/core'
import type { MenuItemConfig } from 'handsontable/plugins/contextMenu'
import { headingTypeCommands } from './headingTypeCommands'
import { removeReferenceIdCommand, setReferenceIdCommand, setReferenceIdForValueCommand } from './referenceIdCommands'
import { removeUnusedCellsCommand } from './removeUnusedCellsCommand'
import { textAlignmentCommands } from './textAlignmentCommands'
import { toggleAttributeDisabledCommand, toggleAttributeExclusiveCommand } from './toggleAttributeCommands'
import {
  onAfterCreateColFunction,
  onAfterCreateRowFunction,
  onAfterRemoveColFunction,
  onAfterRemoveRowFunction,
} from './utils'
import type { Cell, CustomEventKey, CustomPluginKey } from '@/src/lib/handsontable/plugins/CustomPluginBase'
import { cellCompareFunction, CustomPluginBase } from '@/src/lib/handsontable/plugins/CustomPluginBase'
import type { DisplayMode } from '@/src/pages/Survey'

export type CustomProperty = Cell & {
  referenceId?: string
  headingType?: 'column' | 'line'
  textAlignment?: 'left' | 'center' | 'right'
  disabled?: true
  exclusive?: true
}

export type CustomPropertyPluginOptions = {
  enabled: boolean
  displayMode: DisplayMode
  questionPosition: number
  customProperties: CustomProperty[] | undefined
}

export const isCustomPropertyEqual = (a: CustomProperty, b: CustomProperty): boolean => {
  return (['col', 'row', 'referenceId', 'headingType', 'textAlignment', 'disabled', 'exclusive'] as const).every(
    (p) => a[p] === b[p],
  )
}

export const isCustomPropertiesEqual = (a: readonly CustomProperty[] = [], b: readonly CustomProperty[] = []) => {
  return a.length === b.length && a.every((item, index) => isCustomPropertyEqual(item, b[index]))
}

export const classesFromProperty = (property: CustomProperty, displayMode: DisplayMode): string => {
  return classNames({
    'text-start': property.textAlignment === 'left',
    'text-end': property.textAlignment === 'right',
    'text-center': property.textAlignment === 'center',
    'column-heading': displayMode !== 'print' && property.headingType === 'column',
    'line-heading': displayMode !== 'print' && property.headingType === 'line',
    disabled: property.disabled,
    'custom-reference': displayMode !== 'print' && !!property.referenceId,
  })
}

export class CustomPropertyPlugin extends CustomPluginBase {
  static get PLUGIN_KEY(): CustomPluginKey {
    return 'CUSTOM_PROPERTY_PLUGIN'
  }

  static get SET_CUSTOM_PROPERTY_EVENT(): CustomEventKey {
    return 'SET_CUSTOM_PROPERTY'
  }

  static get REMOVE_CUSTOM_PROPERTY_EVENT(): CustomEventKey {
    return 'REMOVE_CUSTOM_PROPERTY'
  }

  customProperties: CustomProperty[]

  constructor(hotInstance: Core) {
    super(hotInstance)
    this.customProperties = (this.pluginSettings()?.customProperties ?? []).map((customProperty) => {
      return { ...customProperty }
    })
  }

  setup = () => {
    this.addHook('afterCreateCol', this.onAfterCreateCol)
    this.addHook('afterCreateRow', this.onAfterCreateRow)
    this.addHook('afterRemoveCol', this.onAfterRemoveCol)
    this.addHook('afterRemoveRow', this.onAfterRemoveRow)
    this.addHook('afterRenderer', this.onAfterRenderer)
    // NOTE: addHook に本来の引数のメソッドを渡すとエラーになるため `as any` している
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    this.addHook('afterContextMenuDefaultOptions', this.onAfterContextMenuDefaultOptions as any)
  }

  isEnabled = (): boolean => {
    return this.pluginSettings()?.enabled ?? false
  }

  pluginSettings = (): CustomPropertyPluginOptions | undefined => {
    return this.pluginSettingsByKey<CustomPropertyPluginOptions>(CustomPropertyPlugin.PLUGIN_KEY)
  }

  questionPosition = (): number | undefined => {
    return this.pluginSettings()?.questionPosition
  }

  displayMode = (): DisplayMode => {
    return this.pluginSettings()?.displayMode ?? 'edit'
  }

  onAfterCreateCol = (index: number, amount: number, _source?: string) => {
    this.customProperties = onAfterCreateColFunction(this.customProperties, index, amount)
  }

  onAfterCreateRow = (index: number, amount: number, _source?: string) => {
    this.customProperties = onAfterCreateRowFunction(this.customProperties, index, amount)
  }

  onAfterRemoveCol = (index: number, amount: number, _physicalColumns: number[], _source?: string) => {
    this.customProperties = onAfterRemoveColFunction(this.customProperties, index, amount)
  }

  onAfterRemoveRow = (index: number, amount: number, _physicalRows: number[], _source?: string) => {
    this.customProperties = onAfterRemoveRowFunction(this.customProperties, index, amount)
  }

  onAfterContextMenuDefaultOptions = (predefinedItems: { items: MenuItemConfig[] }) => {
    predefinedItems.items.push(
      setReferenceIdForValueCommand(this),
      setReferenceIdCommand(this),
      removeReferenceIdCommand(this),
      toggleAttributeDisabledCommand(this),
      toggleAttributeExclusiveCommand(this),
      headingTypeCommands(this),
      textAlignmentCommands(this),
      removeUnusedCellsCommand(this),
    )
  }

  onAfterRenderer = (
    TD: HTMLTableCellElement,
    row: number,
    col: number,
    _prop: string | number,
    _value: unknown,
    _cellProperties: object,
  ) => {
    const customProperty = this.findCustomProperty({ row, col })
    if (!customProperty) return

    const classes = classesFromProperty(customProperty, this.displayMode())
    if (!classes) return

    // NOTE: 復数のクラスがあるときにそのまま指定するとエラーとなるため分割してから設定している
    TD.classList.add(...classes.split(' '))
  }

  selectedCells = (): readonly CellCoords[] => {
    return this.selectedRange()
      .flatMap((cell): CellCoords[] => cell.getAll())
      .filter((cell) => cell.row >= 0 && cell.col >= 0)
  }

  findCustomProperty = (target: { row: number; col: number }): CustomProperty | undefined => {
    return this.customProperties.find((customProperty) => {
      return customProperty.row === target.row && customProperty.col === target.col
    })
  }

  findCustomProperties = (targets: readonly CellCoords[]): readonly CustomProperty[] => {
    return targets
      .map((target) => this.findCustomProperty(target))
      .filter((customProperty): customProperty is CustomProperty => customProperty !== undefined)
  }

  findOrCreateCustomProperty = (target: { row: number; col: number }): CustomProperty => {
    const property = this.findCustomProperty(target)
    if (property) return property

    const newProperty: CustomProperty = { row: target.row, col: target.col }
    this.customProperties.push(newProperty)
    return newProperty
  }

  findOrCreateCustomProperties = (targets: readonly CellCoords[]): readonly CustomProperty[] => {
    return targets.map((target) => this.findOrCreateCustomProperty(target))
  }

  customPropertiesForSave = (): CustomProperty[] => {
    return [...this.customProperties]
      .filter((customProperty) => {
        return (
          customProperty.headingType ||
          customProperty.referenceId ||
          customProperty.textAlignment ||
          customProperty.disabled ||
          customProperty.exclusive
        )
      })
      .sort(cellCompareFunction)
  }

  customPropertiesForCopy = (coord: RangeType): CustomProperty[] => {
    return this.customPropertiesForSave().flatMap((property) => {
      if (property.row < coord.startRow || property.row > coord.endRow) return []
      if (property.col < coord.startCol || property.col > coord.endCol) return []

      return {
        ...property,
        row: property.row - coord.startRow,
        col: property.col - coord.startCol,
      }
    })
  }

  apply = (properties: CustomProperty[], coord: RangeType) => {
    for (const property of properties) {
      const row = property.row + coord.startRow
      const col = property.col + coord.startCol
      const target = this.findOrCreateCustomProperty({ row, col })

      target.headingType = property.headingType
      target.textAlignment = property.textAlignment
      target.disabled = property.disabled
      target.exclusive = property.exclusive
    }
  }

  runHooksAndRender = (hook: CustomEventKey) => {
    this.hotInstance.runHooks(hook)
    this.hotInstance.render()
  }
}
