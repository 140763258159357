import type CellCoords from 'handsontable/3rdparty/walkontable/src/cell/coords'
import type { MenuItemConfig, SubmenuItemConfig } from 'handsontable/plugins/contextMenu'
import type { CustomCommandsPlugin } from './core'

type SelectedColumnsReturn = {
  index: number
  amount: number
}

const getSelectedColumns = (plugin: CustomCommandsPlugin): SelectedColumnsReturn | false => {
  const selectedRange = plugin.selectedRange()
  if (selectedRange.length !== 1) {
    return false
  }

  const cells: CellCoords[] = selectedRange[0].getAll()
  if (cells.some((cell) => cell.col < 0)) {
    return false
  }

  const columns = cells.map((cell) => cell.col)
  const min = Math.min(...columns)
  const max = Math.max(...columns)

  return {
    index: min,
    amount: max - min + 1,
  }
}

const insertColumnsLeftCommand = (plugin: CustomCommandsPlugin): SubmenuItemConfig => {
  return {
    key: 'alterColumns:left',
    name: '左に追加',
    callback: () => {
      const selectedColumns = getSelectedColumns(plugin)
      if (!selectedColumns) {
        return
      }

      plugin.hot.alter('insert_col_start', selectedColumns.index, selectedColumns.amount)
    },
  }
}

const insertColumnsRightCommand = (plugin: CustomCommandsPlugin): SubmenuItemConfig => {
  return {
    key: 'alterColumns:right',
    name: '右に追加',
    callback: () => {
      const selectedColumns = getSelectedColumns(plugin)
      if (!selectedColumns) {
        return
      }

      plugin.hot.alter('insert_col_end', selectedColumns.index + selectedColumns.amount - 1, selectedColumns.amount)
    },
  }
}

const removeColumnsCommand = (plugin: CustomCommandsPlugin): SubmenuItemConfig => {
  return {
    key: 'alterColumns:remove',
    name: '削除',
    callback: () => {
      const selectedColumns = getSelectedColumns(plugin)
      if (!selectedColumns) {
        return
      }
      if (selectedColumns.index === 0 && selectedColumns.amount === plugin.hotInstance.countCols()) {
        selectedColumns.index = 1
        selectedColumns.amount -= 1
      }

      plugin.hot.alter('remove_col', selectedColumns.index, selectedColumns.amount, 'alterColumns:remove')
    },
  }
}

export const alterColumnsCommands = (plugin: CustomCommandsPlugin): MenuItemConfig => {
  return {
    key: 'alterColumns',
    name: '列',
    submenu: {
      items: [insertColumnsLeftCommand(plugin), insertColumnsRightCommand(plugin), removeColumnsCommand(plugin)],
    },
    disabled: () => !getSelectedColumns(plugin),
  }
}
