import classNames from 'classnames'
import type { ReactNode, ForwardRefRenderFunction } from 'react'
import { forwardRef } from 'react'

export type UpdatedMarkWrapperProps = {
  className?: string
  children: ReactNode
  isUpdated: boolean
}

const UpdatedMarkWrapperFn: ForwardRefRenderFunction<HTMLDivElement, UpdatedMarkWrapperProps> = (props, ref) => {
  return (
    <div ref={ref} className={classNames(props.className, { 'is-updated-content': props.isUpdated })}>
      {props.children}
    </div>
  )
}

export const UpdatedMarkWrapper = forwardRef(UpdatedMarkWrapperFn)
