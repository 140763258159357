import { HotTable } from '@handsontable/react'
import { registerEditor } from 'handsontable/editors'
import { jaJP, registerLanguageDictionary } from 'handsontable/i18n'
import { registerPlugin } from 'handsontable/plugins'
import React from 'react'
import { StyledContentEditor } from './editors/StyledContentEditor'
import { CustomCellSizePlugin } from './plugins/CustomCellSizePlugin'
import { CustomCommandsPlugin } from './plugins/CustomCommandsPlugin'
import { CustomMergeCellsPlugin, type CustomMergeCell } from './plugins/CustomMergeCellsPlugin'
import { CustomPropertyPlugin, type CustomProperty } from './plugins/CustomPropertyPlugin'
import { isEmpty } from '@/src/lib/stringUtils'

export { CustomCellSizePlugin, type CustomCellSizePluginOptions } from './plugins/CustomCellSizePlugin'
export { CustomCommandsPlugin, type CustomCommandsPluginOptions } from './plugins/CustomCommandsPlugin'
export {
  CustomMergeCellsPlugin,
  isCustomMergeCellsEqual,
  type CustomMergeCell,
  type CustomMergeCellsPluginOptions,
} from './plugins/CustomMergeCellsPlugin'
export {
  CustomPropertyPlugin,
  classesFromProperty,
  isCustomPropertiesEqual,
  type CustomProperty,
  type CustomPropertyPluginOptions,
} from './plugins/CustomPropertyPlugin'
export { StyledContentEditor } from './editors/StyledContentEditor'
export { DEFAULT_WIDTH, DEFAULT_HEIGHT } from './constants'

// NOTE: handsontable とやりとりするデータで中身は意識しないため
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TableContent = any[][]
export type TableMetadata = {
  customMergeCells: CustomMergeCell[] | undefined
  customProperties: CustomProperty[] | undefined
  colWidths: number[] | undefined
  rowHeights: number[] | undefined
}

export const MemoizedHotTable = React.memo(HotTable)

export const isTableContentEmpty = (content: TableContent): boolean => {
  return content.every((row) => row.every((cell) => isEmpty(cell)))
}

export const initHandsontable = (): void => {
  registerEditor('styled', StyledContentEditor)
  registerLanguageDictionary(jaJP)

  registerPlugin(CustomCellSizePlugin)
  registerPlugin(CustomCommandsPlugin)
  registerPlugin(CustomMergeCellsPlugin)
  registerPlugin(CustomPropertyPlugin)
}
