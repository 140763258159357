import type CellRange from 'handsontable/3rdparty/walkontable/src/cell/range'
import type Core from 'handsontable/core'
import { BasePlugin } from 'handsontable/plugins'

// NOTE: カスタムプラグイン, カスタムイベントのキーは型エラーになるため `as any` を利用する
/* eslint-disable @typescript-eslint/no-explicit-any */
export type CustomPluginKey = any
export type CustomEventKey = any
/* eslint-enable */

export type Cell = {
  row: number
  col: number
}

export const cellCompareFunction = (a: Cell, b: Cell): number => {
  return a.row === b.row ? a.col - b.col : a.row - b.row
}

export abstract class CustomPluginBase extends BasePlugin {
  hotInstance: Core

  constructor(hotInstance: Core) {
    super(hotInstance)
    this.hotInstance = hotInstance
  }

  enablePlugin = () => {
    if (this.enabled) return

    this.setup()
    super.enablePlugin()
  }

  abstract setup(): void

  selectedRange = (): readonly CellRange[] => {
    return this.hotInstance.getSelectedRange() ?? []
  }

  pluginSettingsByKey = <T>(key: string): T | undefined => {
    // NOTE: 独自のプラグインのキーは型エラーになるので any を経由して設定を取得する
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const settings = this.hotInstance.getSettings() as any
    return settings[key]
  }
}
