import type CellRange from 'handsontable/3rdparty/walkontable/src/cell/range'
import type { RangeType } from 'handsontable/common'
import type Core from 'handsontable/core'
import {
  cellRangeToCustomMergeCell,
  isOverlap,
  onAfterCreateColFunction,
  onAfterCreateRowFunction,
  onAfterRemoveColFunction,
  onAfterRemoveRowFunction,
} from './utils'
import type { Cell, CustomPluginKey } from '@/src/lib/handsontable/plugins/CustomPluginBase'
import { cellCompareFunction, CustomPluginBase } from '@/src/lib/handsontable/plugins/CustomPluginBase'

export type CustomMergeCell = Cell & {
  rowspan: number
  colspan: number
}

export type CustomMergeCellsPluginOptions = {
  enabled: boolean
}

export const isCustomMergeCellEqual = (a: CustomMergeCell, b: CustomMergeCell): boolean => {
  return (['row', 'col', 'rowspan', 'colspan'] as const).every((p) => a[p] === b[p])
}

export const isCustomMergeCellsEqual = (a: readonly CustomMergeCell[] = [], b: readonly CustomMergeCell[] = []) => {
  return a.length === b.length && a.every((item, index) => isCustomMergeCellEqual(item, b[index]))
}

export class CustomMergeCellsPlugin extends CustomPluginBase {
  static get PLUGIN_KEY(): CustomPluginKey {
    return 'CUSTOM_MERGE_CELLS_PLUGIN'
  }

  customMergeCells: CustomMergeCell[]

  constructor(hotInstance: Core) {
    super(hotInstance)
    this.customMergeCells = []
  }

  setup = () => {
    this.addHook('afterMergeCells', this.onAfterMergeCells)
    this.addHook('afterUnmergeCells', this.onAfterUnmergeCells)
    this.addHook('afterCreateCol', this.onAfterCreateCol)
    this.addHook('afterCreateRow', this.onAfterCreateRow)
    this.addHook('afterRemoveCol', this.onAfterRemoveCol)
    this.addHook('afterRemoveRow', this.onAfterRemoveRow)
  }

  isEnabled = (): boolean => {
    if (!this.hotInstance.getPlugin('mergeCells').enabled) return false

    return this.pluginSettings()?.enabled ?? false
  }

  pluginSettings = (): CustomMergeCellsPluginOptions | undefined => {
    // NOTE: 独自のプラグインのキーは型エラーになるので any を経由して設定を取得する
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const settings = this.hotInstance.getSettings() as any
    return settings[CustomMergeCellsPlugin.PLUGIN_KEY]
  }

  onAfterMergeCells = (cellRange: CellRange, _mergeParent: unknown, _auto?: boolean) => {
    const merged = cellRangeToCustomMergeCell(cellRange)
    const alreadyMerged = this.customMergeCells.find((customMergeCell) => {
      return isCustomMergeCellEqual(merged, customMergeCell)
    })
    if (alreadyMerged) return

    this.customMergeCells = this.customMergeCells.filter((customMergeCell) => {
      return !isOverlap(merged, customMergeCell)
    })
    this.customMergeCells.push(merged)
  }

  onAfterUnmergeCells = (cellRange: CellRange, _auto?: boolean) => {
    const unmerged = cellRangeToCustomMergeCell(cellRange)
    this.customMergeCells = this.customMergeCells.filter((customMergeCell) => {
      return !isOverlap(unmerged, customMergeCell)
    })
  }

  onAfterCreateCol = (index: number, amount: number, _source?: string) => {
    this.customMergeCells = onAfterCreateColFunction(this.customMergeCells, index, amount)
  }

  onAfterCreateRow = (index: number, amount: number, _source?: string) => {
    this.customMergeCells = onAfterCreateRowFunction(this.customMergeCells, index, amount)
  }

  onAfterRemoveCol = (index: number, amount: number, _physicalColumns: number[], _source?: string) => {
    this.customMergeCells = onAfterRemoveColFunction(this.customMergeCells, index, amount)
  }

  onAfterRemoveRow = (index: number, amount: number, _physicalRows: number[], _source?: string) => {
    this.customMergeCells = onAfterRemoveRowFunction(this.customMergeCells, index, amount)
  }

  isFirstOfMergedCell = (cell: Cell): boolean => {
    return this.customMergeCellsForSave().some((mergeCell) => {
      return mergeCell.row === cell.row && mergeCell.col === cell.col
    })
  }

  isRestOfMergedCell = (cell: Cell): boolean => {
    for (const mergeCell of this.customMergeCellsForSave()) {
      if (
        mergeCell.row <= cell.row &&
        mergeCell.col <= cell.col &&
        cell.row < mergeCell.row + mergeCell.rowspan &&
        cell.col < mergeCell.col + mergeCell.colspan
      ) {
        return mergeCell.row !== cell.row || mergeCell.col !== cell.col
      }
    }

    return false
  }

  customMergeCellsForSave = (): CustomMergeCell[] => {
    return [...this.customMergeCells].sort(cellCompareFunction)
  }

  customMergeCellsForCopy = (coord: RangeType): CustomMergeCell[] => {
    return this.customMergeCellsForSave().flatMap((mergeCell) => {
      if (mergeCell.row < coord.startRow || mergeCell.row > coord.endRow) return []
      if (mergeCell.col < coord.startCol || mergeCell.col > coord.endCol) return []

      return {
        ...mergeCell,
        row: mergeCell.row - coord.startRow,
        col: mergeCell.col - coord.startCol,
      }
    })
  }

  apply = (mergeCells: CustomMergeCell[], coord: RangeType) => {
    this.customMergeCells = [
      ...this.customMergeCellsForSave(),
      ...mergeCells.map((mergeCell) => {
        return {
          ...mergeCell,
          row: mergeCell.row + coord.startRow,
          col: mergeCell.col + coord.startCol,
        }
      }),
    ]
  }
}
