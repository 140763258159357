export const wrapSelectionWithTag = (target: HTMLTextAreaElement, tagName: string) => {
  const { value, selectionStart, selectionEnd } = target
  const selectionValue = value.slice(selectionStart, selectionEnd)

  target.focus()
  target.setRangeText(`<${tagName}>${selectionValue}</${tagName}>`, selectionStart, selectionEnd, 'end')
  target.dispatchEvent(new Event('change', { bubbles: true }))

  // もとの選択範囲を維持するために、追加したタグの文字数分だけずらしている
  const offset = tagName.length + 2
  target.setSelectionRange(selectionStart + offset, selectionEnd + offset)
}
